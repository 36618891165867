<template>
  <div id="attendance-info" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <p class="title is-4 has-text-right">
        {{ schoolClassName }}
      </p>
      <b-table :data="attendances" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">{{
          attendances.indexOf(props.row) + 1
        }}</b-table-column>

        <b-table-column label="Month" v-slot="props">{{
          props.row.month
        }}</b-table-column>

        <b-table-column label="DM" v-slot="props">
          {{ props.row.days_in_month }}
        </b-table-column>

        <b-table-column label="TA" v-slot="props">{{
          props.row.total_attendance
        }}</b-table-column>

        <b-table-column label="NP" v-slot="props">{{
          props.row.number_present
        }}</b-table-column>

        <b-table-column label="NA" v-slot="props">{{
          props.row.number_absent
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row.month)"
            :show-update="false"
            :show-remove="false"
          />
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchTerm } from '@/assets/js/app_info.js'

export default {
  name: 'AttendanceInfo',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Attendance Info',
      attendances: [],
      schoolClassName: null,
      schoolClassAttendance: [],
    }
  },
  watch: {
    schoolClassAttendance(data) {
      this.schoolClassName = data.name
      this.attendances = data.attendances
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.schoolClassId = parseInt(this.$route.params.id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Attendances',
        route: `/school/${this.schoolId}/attendances`,
      },
    ])

    fetchTerm().then((term) => {
      this.termId = term.id

      this.$apollo.addSmartQuery('schoolClassAttendance', {
        query: gql`
          query SchoolClassAttendanceQuery($id: ID!, $termId: ID!) {
            schoolClassAttendance(id: $id, termId: $termId) {
              name
              attendances
            }
          }
        `,
        variables: {
          id: parseInt(this.schoolClassId),
          termId: parseInt(this.termId),
        },
      })

      this.$apollo.queries.schoolClassAttendance.refetch()
    })
  },
  methods: {
    initializeInfo(month) {
      this.$router.push(
        `/school/${this.schoolId}/school_class_attendance_info/${this.schoolClassId}/month/${month}`
      )
    },
  },
}
</script>
